import * as React from "react";
import AssignmentTable from "../../components/table/assignmenttable";

export default function Assignment() {
  return (
    <div>
      <AssignmentTable />
    </div>
  );
}
