import React from "react";
import SidebarMenu from "./sidebarmenu";

const Sidebar = () => {
  return (
    <div>
      <SidebarMenu></SidebarMenu>
    </div>
  );
};

export default Sidebar;
