import React from "react";

const DayWorkingTimeIcon = ({ width, height, color }) => {
  return (
    <svg fill={color} height={width + "px"} width={height + "px"} viewBox="0 0 512 512" version="1.1">
      <g id="Working_Hours">
        <g id="XMLID_383_">
          <path
            d="M416,338.747v-6.122c0-13.853-9-25.457-23-28.273v43.321c0,5.502-3.998,9.963-9.5,9.963    c-5.502,0-9.5-4.461-9.5-9.963v-43.467c-14,2.532-24,14.308-24,28.419v53.472c0,5.502-4.498,9.963-10,9.963    c-5.502,0-10-4.461-10-9.963v-30.768c-21,13.083-36.89,33.548-43.934,57.278c-0.096,1.306-0.33,2.377-0.907,3.531    c-0.594,2.325-1.173,4.861-1.603,6.861h198.991c-3.619-20-13.353-38.346-28.078-53.086c-5.59-5.59-11.469-10.245-18.469-14.342    v30.525c0,5.502-4.498,9.963-10,9.963c-5.502,0-10-4.461-10-9.963v-46.422C416,339.367,416,339.058,416,338.747z"
            id="XMLID_384_"
          />

          <path
            d="M209.104,196.056c-0.615,0.119-1.248,0.186-1.897,0.186c-0.536,0-1.059-0.054-1.572-0.136    c-5.489,0.91-9.692,5.677-9.692,11.42c0,6.39,5.198,11.589,11.588,11.589c6.39,0,11.589-5.199,11.589-11.589    C219.121,201.669,214.751,196.827,209.104,196.056z"
            id="XMLID_779_"
          />

          <rect height="25" id="XMLID_780_" width="202" x="282" y="443" />

          <rect height="22" id="XMLID_784_" width="260" x="250" y="488" />

          <path
            d="M330,332.667v-0.042C330,305.686,352.029,284,378.968,284h8.422c3.642,0,7.187,0.298,10.603,1.057    c10.009-24.556,15.094-50.646,15.094-77.51c0-113.344-92.212-205.566-205.556-205.566C94.188,1.981,1.977,94.187,1.977,207.531    s92.212,205.553,205.555,205.553c20.613,0,40.843-3.038,60.33-9.019C277.866,373.186,300,347.223,330,332.667z M358.638,197    h20.921c5.502,0,9.963,4.498,9.963,10s-4.461,10-9.963,10h-20.921c-5.502,0-9.963-4.498-9.963-10S353.136,197,358.638,197z     M307.163,93.805l15.24-15.24c3.892-3.89,10.198-3.89,14.09,0c3.891,3.891,3.891,10.199,0,14.09l-15.24,15.24    c-1.946,1.945-4.495,2.918-7.045,2.918c-2.55,0-5.099-0.973-7.045-2.918C303.272,104.004,303.272,97.695,307.163,93.805z     M197,34.847c0-5.502,4.498-9.963,10-9.963c5.502,0,10,4.46,10,9.963v21.918c0,5.502-4.498,9.963-10,9.963    c-5.502,0-10-4.46-10-9.963V34.847z M56.771,217H34.853c-5.502,0-9.963-4.498-9.963-10s4.461-10,9.963-10h21.918    c5.502,0,9.963,4.498,9.963,10S62.273,217,56.771,217z M107.9,321.246l-15.239,15.24c-1.945,1.945-4.495,2.918-7.045,2.918    c-2.55,0-5.099-0.973-7.045-2.918c-3.891-3.891-3.891-10.198,0-14.09l15.239-15.24c3.89-3.89,10.198-3.89,14.09,0    C111.791,311.047,111.791,317.354,107.9,321.246z M107.9,107.894c-1.946,1.945-4.496,2.918-7.045,2.918    c-2.55,0-5.099-0.973-7.045-2.918l-15.239-15.24c-3.891-3.891-3.891-10.199,0-14.089c3.893-3.89,10.199-3.89,14.09,0l15.239,15.24    C111.791,97.696,111.791,104.004,107.9,107.894z M217,379.553c0,5.502-4.498,9.963-10,9.963c-5.502,0-10-4.461-10-9.963v-20.921    c0-5.502,4.498-9.963,10-9.963c5.502,0,10,4.461,10,9.963V379.553z M207.531,238.958c-17.376,0-31.636-14.097-31.636-31.473    c0-13.775,9.104-25.465,21.104-29.74v-35.301c0-5.502,4.498-9.963,10-9.963c5.502,0,10,4.46,10,9.963v35.081    C227,180.557,234.081,188,237.313,197h56.569c5.502,0,9.963,4.498,9.963,10s-4.461,10-9.963,10h-56.348    C233.451,230,221.548,238.958,207.531,238.958z"
            id="XMLID_792_"
          />
        </g>
      </g>

      <g id="Layer_1" />
    </svg>
  );
};

export default DayWorkingTimeIcon;
