import React from "react";

const MinusIcon = ({ width, height, color }) => {
  return (
    <svg fill={color} width={width + "px"} height={height + "px"} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
      <path d="M 7.7148 49.5742 L 48.2852 49.5742 C 53.1836 49.5742 55.6446 47.1367 55.6446 42.3086 L 55.6446 13.6914 C 55.6446 8.8633 53.1836 6.4258 48.2852 6.4258 L 7.7148 6.4258 C 2.8398 6.4258 .3554 8.8398 .3554 13.6914 L .3554 42.3086 C .3554 47.1602 2.8398 49.5742 7.7148 49.5742 Z M 7.7851 45.8008 C 5.4413 45.8008 4.1288 44.5586 4.1288 42.1211 L 4.1288 13.8789 C 4.1288 11.4414 5.4413 10.1992 7.7851 10.1992 L 48.2147 10.1992 C 50.5350 10.1992 51.8708 11.4414 51.8708 13.8789 L 51.8708 42.1211 C 51.8708 44.5586 50.5350 45.8008 48.2147 45.8008 Z M 36.9648 29.8867 C 38.2538 29.8867 39.1444 29.2305 39.1444 28.0118 C 39.1444 26.7696 38.3241 26.0430 36.9648 26.0430 L 19.0351 26.0430 C 17.6992 26.0430 16.8554 26.7696 16.8554 28.0118 C 16.8554 29.2305 17.7460 29.8867 19.0351 29.8867 Z" />
    </svg>
  );
};

export default MinusIcon;
