import React from "react";

const BreakingIcon = ({ width, height, color }) => {
  return (
    <svg
      fill={color}
      height={width + "px"}
      width={height + "px"}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 495.477 495.477"
    >
      <path
        d="M482.477,309.314h-75.546v-8.364h1.054c14.438,0,26.184-11.746,26.184-26.184V149.563
   c-0.004-14.436-11.75-26.181-26.186-26.182l-73.733,0c30.943-2.766,55.284-28.826,55.284-60.478
   c0-33.489-27.245-60.734-60.733-60.734c-33.489,0-60.734,27.245-60.734,60.734c0,31.651,24.341,57.711,55.284,60.478l-73.734-0.001
   c-14.437,0-26.184,11.745-26.186,26.184l0.001,125.201c0,14.437,11.745,26.184,26.185,26.186h1.053v8.364h-97.963
   c3.379-2.348,5.595-6.251,5.595-10.677v-2h8.125c22.538,0,40.875-18.336,40.875-40.875s-18.337-40.875-40.875-40.875H158.3v-3.75
   c0-7.18-5.82-13-13-13H66.8c-7.18,0-13,5.82-13,13v87.5c0,4.426,2.216,8.33,5.595,10.677H13c-7.18,0-13,5.82-13,13
   c0,7.18,5.82,13,13,13h17.748v144.994c0,7.18,5.82,13,13,13c7.18,0,13-5.82,13-13V335.314h193.92v121.685
   c0,19.146,15.576,34.722,34.722,34.722h7.604c17.92,0,32.156-13.608,34.62-33.092c0.325-2.571,0.722-5.624,1.175-9.044
   c0.465,3.471,0.872,6.543,1.202,9.086c2.523,19.46,16.757,33.05,34.613,33.05h7.604c19.146,0,34.724-15.576,34.724-34.722V335.314
   h31.797v144.994c0,7.18,5.82,13,13,13c7.18,0,13-5.82,13-13V335.314h17.749c7.18,0,13-5.82,13-13
   C495.477,315.134,489.656,309.314,482.477,309.314z M294.065,62.902c0-19.152,15.582-34.734,34.734-34.734
   c19.152,0,34.733,15.582,34.733,34.734c0,19.153-15.581,34.734-34.733,34.734C309.647,97.637,294.065,82.055,294.065,62.902z
    M166.425,240.887c8.202,0,14.875,6.673,14.875,14.875c0,8.202-6.673,14.875-14.875,14.875H158.3v-29.75H166.425z M79.8,224.137
   h52.5v61.5H79.8V224.137z M263.668,274.95h-14.051c-0.103,0-0.187-0.083-0.187-0.186l-0.001-125.199
   c0-0.102,0.083-0.186,0.186-0.186l55.243,0l10.163,12.733l-12.783,76.008c-0.646,3.843,0.466,7.773,3.029,10.709l13.74,15.73
   c2.469,2.826,6.038,4.448,9.791,4.448c3.752,0,7.322-1.622,9.79-4.447l13.74-15.729c2.564-2.935,3.677-6.866,3.03-10.709
   l-12.783-76.008l10.165-12.734l55.24,0.001c0.103,0,0.187,0.085,0.187,0.186v125.201c0,0.101-0.083,0.184-0.186,0.184h-14.052
   c-7.18,0-13,5.82-13,13v21.364H276.668V287.95C276.668,280.771,270.848,274.95,263.668,274.95z M380.931,456.999
   c0,4.81-3.913,8.722-8.724,8.722h-7.604c-5.906,0-8.329-6.534-8.83-10.395c-3.26-25.133-13.993-101.622-14.101-102.392
   c-0.9-6.416-6.388-11.189-12.866-11.193c-0.003,0-0.005,0-0.008,0c-6.475,0-11.963,4.766-12.871,11.177
   c-0.108,0.76-10.805,76.306-14.109,102.45c-0.486,3.845-2.892,10.353-8.825,10.353h-7.604c-4.809,0-8.722-3.913-8.722-8.722V335.314
   h104.263V456.999z"
      />
    </svg>
  );
};

export default BreakingIcon;
