import React from "react";

const WorkingIcon = ({ width, height, color }) => {
  return (
    <svg
      fill={color}
      height={width + "px"}
      width={height + "px"}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460.007 460.007"
    >
      <g>
        <path
          d="M321.552,1.004c-49.074,0-92.263,25.666-116.858,64.277c11.696,14.845,16.069,34.707,10.629,53.695
		c-4.978,17.374-17.108,30.81-32.497,37.911c4.007,3.75,7.424,8.291,9.95,13.329c2.492-1.988,5.249-3.531,8.104-4.617
		c14.829-5.649,31.646,1.29,38.104,15.942l6.942,15.744c6.313,1.482,12.18,4.564,17.05,9.031
		c6.283,5.763,10.403,13.373,11.81,21.702h38.829c16.542,0,30,13.458,30,30c0,4.687-1.094,9.471-3.443,13.93
		c1.146,1.335,2.193,2.755,3.13,4.253c66.047-10.466,116.704-67.796,116.704-136.742C460.007,63.115,397.897,1.004,321.552,1.004z
		 M338.338,184.172l-28.462-35.297c-2.15-2.666-3.323-5.99-3.323-9.415V66.951c0-8.284,6.716-15,15-15c8.284,0,15,6.716,15,15
		v67.214l25.139,31.176c5.2,6.449,4.188,15.892-2.261,21.092C352.986,191.63,343.541,190.624,338.338,184.172z"
        />
        <path
          d="M228.492,429.859l-39.104-109.732c-4.552-12.772-5.399-17.531-20.256-22.294l-19.055-6.108l4.108-14.34
		c-8.147-1.373-15.038-6.865-18.25-14.389l-28.324-66.61l43.169,54.947c3.657,4.655,9.486,7.36,15.687,6.869l37.343-2.972
		c-0.257,0.886-0.402,1.819-0.402,2.788c0,5.523,4.477,10,10,10c22.94,0,77.556,0,100.206,0c5.551,0,10-4.511,10-10
		c0-5.525-4.479-10-10-10c-7.168,0-57.594,0-64.495,0h0c4.159-3.645,6.617-9.124,6.145-15.061
		c-0.793-9.976-9.523-17.417-19.501-16.626l-3.184,0.253c-10.034-22.755-7.149-16.213-11.894-26.975
		c-2.213-5.019-8.131-7.344-13.185-5.116c-5.053,2.229-7.344,8.132-5.115,13.186c2.864,6.496,5.607,12.716,9.077,20.585
		l-38.184,3.039l-37.166-47.307l39.624,28.173c0.928-3.239,1.109-3.811,1.342-4.812c3.23-13.898-4.959-28.081-18.882-32.069
		c-6.411-1.837-37.789-10.826-44.197-12.662c-15.168-4.347-30.789,5.196-33.919,20.591L53.433,304.294
		c-1.652,8.13,1.184,16.524,7.444,21.986H49.31c-0.846-6.679-18.576-146.684-19.427-153.409
		c-1.041-8.218-8.549-14.033-16.766-12.997c-8.218,1.041-14.037,8.547-12.997,16.766l21.088,166.524
		c0.95,7.496,7.326,13.116,14.881,13.116h47.352v49.49l-32.599,26.611c-6.417,5.239-7.373,14.688-2.134,21.105
		c5.24,6.418,14.688,7.374,21.106,2.135l28.627-23.369l28.627,23.369c6.417,5.237,15.867,4.284,21.106-2.135
		c5.239-6.417,4.283-15.866-2.134-21.105l-32.598-26.611V356.28h42.66l31.424,88.179c4.031,11.312,16.469,17.214,27.783,13.184
		C226.635,453.606,232.513,441.141,228.492,429.859z"
        />
        <path
          d="M146.631,140.902c21.237,6.084,43.382-6.201,49.465-27.434c6.084-21.235-6.199-43.382-27.434-49.466
		c-21.236-6.083-43.382,6.2-49.465,27.434C113.111,112.678,125.403,134.821,146.631,140.902z"
        />
        <path
          d="M313.615,279.707H213.409c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h85.206v118.295h-35.103c-8.284,0-15,6.716-15,15
		c0,8.284,6.716,15,15,15h50.103c8.284,0,15-6.716,15-15V294.707C328.615,286.423,321.899,279.707,313.615,279.707z"
        />
      </g>
    </svg>
  );
};

export default WorkingIcon;
